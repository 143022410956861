<template>
	<div class="tw-bg-red-50 tw-grid tw-place-items-center">
		<div class="mx-auto tw-max-w-7xl">
			<img src="@/assets/images/404.svg" class="w-full" />
			<div class="mt-10 text-center">
				<h1 class="tw-text-2xl">{{ $t('page-not-found') }}</h1>
				<button @click="onClickGoBack" class="tw-text-primary tw-text-sm">
					{{ $t('go-back') }}
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { hasHistory } from 'vuelpers'
import { getDefaultRoute } from '../helpers'

export default {
	name: 'NotFound',
	computed: {
		...mapGetters('auth', ['$currentUser']),
	},
	methods: {
		onClickGoBack() {
			const defaultRoute = this.$currentUser
				? getDefaultRoute(this.$currentUser.role)
				: '/'
			hasHistory() ? this.$router.back() : this.$router.replace(defaultRoute)
		},
	},
}
</script>
